import React from 'react';
import { graphql } from 'gatsby';
import { EntryContextProvider } from '@context/EntryContext';
import ArticleContent from '@c/ArticleContent';
import Layout from '@c/Layout';
import Hero from '@c/Hero';

const Cooperation = ({ data }) => {
  const { articleContent, hero, seomatic } = data.cms.entry;

  return (
    <EntryContextProvider entry={data.cms.entry}>
      <Layout seomatic={seomatic}>
        {!!hero && !!hero.length && <Hero {...hero[0]} />}

        <ArticleContent content={articleContent} />
      </Layout>
    </EntryContextProvider>
  );
};

export default Cooperation;

export const query = graphql`
  query {
    cms {
      entry(section: "cooperation") {
        uri
        ... on cms_cooperation_cooperation_Entry {
          hero {
            ...heroFields
          }
          articleContent {
            ...articleContentFields
          }
        }

        seomatic(uri: "/") {
          ...seomaticFields
        }
      }
    }
  }
`;
